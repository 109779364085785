// Babel polyfills must come first
//import 'core-js/stable';
//import 'regenerator-runtime/runtime';

import $ from 'jquery'



import csrf from './csrf.js'
import authMobile from './auth/auth-mobile.js'
import authSubmission from './auth/auth-submission.js'
import autoScrollTo from './autoscroll-to.js'
import resizeReader from './resize-reader.js'
import pdpOnlineTimer from './pdp-online-timer.js'
import siteBanner from './site-banner.js'
import gyl from './gyl.js'
import initNavMenu from './header.js'
import langdetail from './langdetail.js'
import profile from './profile'
import reactListen from './react-listen.js'
import salesleadsSubmit from './salesleads.js'
import userFeedbackFormSubmit from './feedback.js'
import socialLinks from './social.js'
import updateHeader from './cart/updateHeader.js'
import cartListeners from './cart_listeners.js'
import toggleTeacherInfoDisplay from './pdp-instance-teacher.js'
import fc_dropdown from './fc-dropdown.js'
import initActivity from './nlp/activity.js'
import initFindclassesCTA from './findclasses-cta.js'
import Modal from 'react-modal';
import testimonials from './testimonials.js'
import learningPlans from './learning-plans.js'
import togglePlanType from './toggle-plan-type.js'
import initPackageDropdown from './initPackageDropdown.js'
import initQuestionAndAnswerSections from './question-and-answer.js'
import initDynamicForms from './dynamic-form.js'
import calcDynamicMargins from './dynamic-margins.js'
import selectFix from './select-fix.js';

Modal.defaultStyles.content = {};

$(init)
authMobile()
authSubmission()
testimonials()
learningPlans()
togglePlanType()
cartListeners()
resizeReader()
//b2bScrollWatch()
pdpOnlineTimer()
gyl()
initNavMenu()
langdetail()
profile()
reactListen()
salesleadsSubmit()
userFeedbackFormSubmit();
socialLinks()
toggleTeacherInfoDisplay()
fc_dropdown()
initActivity()
initFindclassesCTA();
initPackageDropdown();
initQuestionAndAnswerSections()
initDynamicForms()
calcDynamicMargins()
selectFix()
siteBanner()

window.autoScrollTo = autoScrollTo

function init() {
    csrf.setupAjax()
    updateHeader()
}

if (module.hot) {
    module.hot.accept()
}
