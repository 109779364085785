var bannerController
var banner

const cmsToolbar = document.getElementsByClassName('cms-toolbar')[0];
const siteBanner = document.getElementsByClassName('banner-content')[0];
const htmlElement = document.getElementsByTagName('html')[0];
const header = document.getElementsByTagName('header')[0];

export default function siteBannerController () {
    window.addEventListener('scroll', scrollEvent)
    window.addEventListener('resize', bannerPosition)

    // Fix if Django CMS toolbar present
    if ($('.cms-toolbar').length) {
      $('.site-banner').css('top', $('.cms-toolbar').height() + 'px');
    }

    bannerPosition();

}

onload = () => {
    bannerController = getBannerController()
    banner = document.getElementsByClassName('sub-banner')[0]
    scrollEvent()
}

var getBannerController = () => {
    return {
        container: document.getElementsByClassName('site-banner')[0],
        expanded: false
    }
}

var scrollEvent = () => {
    if (!bannerController) { return false }


    // The list contains typically 2 or 3 elements, depending on if it's visible.
    // As such, doing a string search in the array is acceptable.
    if (!banner) { return false }

    var firstBreak = 200
    if (banner.classList.contains('private') ||
        banner.classList.contains('group')) {
        var navBarStart = document.getElementsByClassName('page-nav-container')[0].
            getBoundingClientRect().top
        var pageStart = document.body.getBoundingClientRect().top
        firstBreak = navBarStart - pageStart
    }
    var secondBreak = 1600

    var expanded = bannerController.expanded
    if (window.pageYOffset > firstBreak && !expanded) {
        bannerController.expanded = true
        bannerController.container.classList.add('expanded')
        bannerController.container.classList.remove('retracted')
        banner.classList.add('visible')
    } else if (window.pageYOffset <= firstBreak && expanded) {
        bannerController.expanded = false
        bannerController.container.classList.remove('expanded')
        bannerController.container.classList.remove('retracted')
        banner.classList.remove('visible')
    } else if (window.pageYOffset > secondBreak) {
        bannerController.container.classList.add('retracted')
    } else {
        bannerController.container.classList.remove('retracted')
    }
}



function bannerPosition() {
    let top;
    let headerTop;
    if (cmsToolbar) {
        if (siteBanner) {
            if (window.length > '26em') {
                top = '9.75rem';
                headerTop = '-4rem';
            } else {
                top = '8.75rem';
                headerTop = '-3rem';
            }
        } else {
            if (window.length > '26em') {
                top = '6.875rem';
                headerTop = '-4rem';
            } else {
                top = '5.875rem';
                headerTop = '-3rem';
            }
        }
        document.addEventListener('DOMContentLoaded', function () {
            const targetElement = document.documentElement;
            if (targetElement) {
                const observer = new MutationObserver((mutationsList) => {
                    mutationsList.forEach((mutation) => {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                            htmlElement.style.marginTop = top;
                            header.style.marginTop = headerTop;
                        }
                    });
                });
        
                const config = {
                    attributes: true,
                    childList: false, 
                    subtree: false
                };
        
                observer.observe(targetElement, config);
            }
        });
    } else if (siteBanner) {
        htmlElement.style.marginTop = '5.75rem';
        header.style.marginTop = '-3rem';
    } else {
        htmlElement.style.marginTop = '4rem';
    }
}